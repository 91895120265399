import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/layout';
import SEO from 'components/seo';

import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import Components from 'components/CmsComponents/';
import { ListPostContent } from 'components/CmsComponents/ListPostContent';

import { HeaderContext } from 'context/header';

const Coronavirus = ({
  data: {
    gcms: { pages, posts },
  },
  pageContext,
}) => {
  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);
  const page = pages?.[0];
  const featuredMarketingContent = page?.featuredMarketing;
  const components = page?.components ?? [];
  const customListPostData = page?.components?.find(
    component => component.__typename === 'GraphCMS_ListPostContent'
  );
  const customListPostLink = customListPostData
    ? customListPostData?.headerLink
        ?.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove accents ?? ''
    : '';

  const dataSeo = [
    {
      seo: {
        metaTitle: page?.seo?.metaTitle ?? '',
        metaDescription: page?.seo?.metaDescription ?? '',
      },
    },
  ];

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <Layout>
      <SEO
        dataSeo={dataSeo}
        image={page?.featuredMarketing?.images?.[0]?.url ?? ''}
      />
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      {components.length >= 1 &&
        components?.map(component => {
          return Components(component);
        })}
      {customListPostData && (
        <ListPostContent
          type={customListPostData.type ?? ''}
          title={customListPostData.title ?? ''}
          headerLinkTitle={customListPostData.headerLinkTitle ?? ''}
          headerLink={customListPostLink ?? ''}
          posts={posts}
          sendTo={{ url: 'imprensa', category: 'Press-Releases' }}
        />
      )}
    </Layout>
  );
};

export const CoronavirusPageQuery = graphql`
  query {
    gcms {
      pages(locales: [pt_BR, en], where: { id: "ckwntifwg0inj0b26caw8832x" }) {
        id
        name
        seo {
          id
          metaTitle
          metaDescription
          noIndex
        }
        featuredMarketing {
          id
          type
          breadcrumb
          backgroundColor {
            hex
          }
          images {
            handle
            width
            height
            url
          }
          title
          fragments {
            id
            name
            type
            markdown
          }
        }
        components {
          __typename
          ... on GraphCMS_SimpleContent {
            id
            name
            type
            title
            fragments {
              id
              name
              type
              markdown
              datafragment
            }
          }
          ... on GraphCMS_HighlightContent {
            id
            name
            type
            backgroundColorInitial {
              hex
            }
            backgroundColorFinal {
              hex
            }
            title
            customTitle
            description
            images {
              handle
              width
              height
              url
            }
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_HighlightFormLeadContent {
            id
            name
            type
            backgroundColorInitial {
              hex
            }
            backgroundColorFinal {
              hex
            }
            title
            customTitle
            description
            referenceToSalesforce
            images {
              handle
              width
              height
              url
            }
            documentsForDownload {
              handle
              width
              height
              url
            }
            footnote
          }
          ... on GraphCMS_VideoGalleryContent {
            id
            name
            type
            title
            headerLinkTitle
            headerLink
            description
            fragments {
              id
              name
              type
              markdown
            }
          }
          ... on GraphCMS_ListPostContent {
            id
            name
            type
            title
            headerLinkTitle
            headerLink
            posts {
              id
              slugPost
              title
              topic
              author
              doctors(first: 1000, locales: [pt_BR, en]) {
                id
                id_api
                active
                assignment
                name
              }
              assetpicker {
                handle
                height
                width
                url
              }
              categories {
                tag
              }
            }
          }
        }
      }
      posts(
        locales: [pt_BR, en]
        where: {
          typeOfPost: PressReleases
          blogPressreleasesAssunto_some: { assunto: "Coronavírus" }
        }
        first: 3
        orderBy: dataEHora_DESC
      ) {
        id
        slugPost
        title
        topic
        author
        doctors(first: 1000, locales: [pt_BR, en]) {
          id
          id_api
          active
          assignment
          name
        }
        assetpicker {
          handle
          height
          width
          url
        }
        categories {
          tag
        }
      }
    }
  }
`;

export default Coronavirus;
